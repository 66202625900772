@import "style.scss";


.paginationBttns {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: end;
  list-style: none;
  // position: absolute;
  // bottom: 15px;
  right: 20px;
}

.paginationBttns a {
  padding: 6px 10px 6px 10px;
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid #53B7E8;
  color: #53B7E8 !important;
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
}

.paginationBttns a:hover {
  color: white !important;
  background-color: #53B7E8;
}

.paginationActive a {
  color: white !important;
  background-color: #53B7E8;
}

@media all and (max-width: 768px) {
  .paginationBttns {
    li {
      margin-bottom: 20px !important;
    }
  }
}


.page-numbers {
  margin-left: 20px !important;
}

.active-pageno {
  color:  #0d6efd!important;
}

.paginate {
  display: flex;
  justify-content: end;
  align-items: center;
  flex: initial;
}

.nextBtn {
  text-align: start;
}

.preBtn {
  text-align: end;
}
.pagenationBtn {
  margin-left: 30px;
}
.pagenationBtn button {
  padding: 0 15px;
}

.verticle-line {
  color: black;
  height: 15px !important;
  width: 2px;
  margin-top: 4px;
  top: -5px;

  background-color: currentColor;
  border: 0;
  opacity: 0.25;
  display: block;
  margin: inherit;
}

.pagination-wrapper .pagination {
  color: #055d9d;
}

#pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}


.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}