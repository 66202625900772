.main-login {
  overflow: hidden !important;

  .wrapper {
    .left {
      background-color: #f1f3f6 !important;
      position: relative;

      .logo {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #007ecc57 0%, #ec008c40 100%);
        img {
          height: 30%;
        }
      }
    }

    .right {
      padding: 30px;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;

      .inner-form {
        .form-control {
          height: 38px;
        }

        .login-btn-main {
          text-align: center;

          .innerLink {
            text-decoration: none !important;

            button {
              width: 100%;
              background: linear-gradient(270deg, #e40288 0.63%, #055d9d 97.5%);
              border-radius: 0px 10px;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #ffffff;
              // border-radius: 10px;
              padding: 10px 20px;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .form-label {
          color: #000;
        }

        width: 100%;

        .heading-login {
          label {
            color: #000;
            font-size: 18px;
          }
        }
      }
    }
  }
}
