.dashboard-main {
  .dashboard-wrapper {
    .main-section {
      justify-content: space-between;
    }
    .left-section {
      // width: calc(50% - 5px);
      h5 {
        color: #44667f;
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: 600;
        border-bottom: 1px solid #898e8e;
        padding: 0 15px 15px 15px;
      }
      background-color: #ffffff;
      border-radius: 10px;
      padding: 15px;
    }
    .right-section {
      .ineerCard {
        .innerText {
          background-color: #747b26;
          .headingText,
          .count {
            color: #ffffff !important;
          }
        }
        .innerIcon {
          background-color: #ffffff !important;
        }
      }
      width: calc(50% - 5px);

      background-color: #ffffff;
      border-radius: 10px;
      padding: 15px;
      h5 {
        color: #44667f;
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: 600;
        border-bottom: 1px solid #898e8e;
        padding: 0 15px 15px 15px;
      }
    }

    .mainCards {
      text-decoration: none;

      .ineerCard {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        max-height: 500px;

        width: 100%;

        display: flex;

        flex-direction: column;

        justify-content: center;

        padding: 5px 0px 15px 5px;

        cursor: pointer;

        background-color: #fff;

        position: relative;

        overflow: hidden;

        margin-bottom: 20px;

        border: 0px solid transparent;

        border-radius: 0.75rem;

        .innerIcon.Basic {
          background: linear-gradient(180deg, #03a731 0%, #027221 100%);
        }
        .innerIcon.Basic-plus {
          background: linear-gradient(180deg, #fdd504 0%, #b37600 100%);
        }
        .innerIcon.Premium-User {
          background: linear-gradient(270deg, #e40288 0.63%, #055d9d 97.5%);
        }
        .innerIcon {
          background: #055d9d;
          width: 25%;

          // padding: 10px;

          text-align: center;

          line-height: 80px;

          svg {
            // color: #fff;

            text-align: center;

            vertical-align: middle;

            margin-top: 10px;

            height: 50px;

            width: 50px;

            line-height: 80px;
          }
        }
      }

      .innerText {
        width: 75%;

        padding: 12px 50px 12px 30px;

        display: flex;

        flex-direction: row;

        align-items: center;

        justify-content: space-between;

        .headingText {
          color: #013053;
          font-size: 18px;
          font-weight: 600;

          text-decoration: none;
        }

        .count {
          margin-top: 0px;
          font-size: 26px;

          font-weight: 600;

          color: #013053;
          text-align: end;
        }
      }
    }
  }
}

.heading-btn-main {
  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-bottom: 20px;
}

.heading-common {
  label {
    font-size: 24px;

    font-weight: 700;

    color: #84859d;
  }
}

.chart-heading {
  padding: 10px;
  label {
    font-size: 16px;

    font-weight: 700;

    color: #84859d;
  }
}

.buttons-modal {
  button,
  a {
    text-decoration: none;

    border: none;

    background-color: #44667f;

    color: #fff;

    border-radius: 10px;

    padding: 5px 15px;
  }
}

.admin-main {
  .add-btn {
    text-align: end;

    button {
      border: none;

      color: #fff;

      border-radius: 5px;

      padding: 7px 10px;

      background-color: #84859d;

      &:hover {
        background-color: #696a7d;
      }

      svg {
        height: 20px;

        margin-bottom: 2px;
      }
    }
  }
}

.table-wrapper {
  overflow-x: auto;

  background-color: #fff;

  padding: 20px;

  border-radius: 10px;

  table {
    th {
    }

    td {
    }

    min-width: 900px;

    thead {
      background-color: #44667f;

      th {
        color: #fff;
      }
    }

    .verify {
      a {
        display: inline-block;
        width: 130px;
        padding: 3px;
        color: #fff;
        text-decoration: none;
        border-radius: 5px;

        background-color: #84859d;

        &:hover {
          background-color: #696a7d;
        }
      }
    }

    .action {
      .icon {
        img {
          height: 18px;

          width: 18px;

          vertical-align: middle;
        }

        margin: 0 3px;
        padding: 2px 5px;

        border-radius: 5px;

        background-color: #84859d;
      }
    }
  }
}

table.dataTable thead th {
  text-align: center;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 20px;
}

.dataTables_wrapper .dataTables_length select {
  margin-left: 10px;

  width: 150px;

  outline: none;
}

.dataTables_wrapper .dataTables_filter input {
  outline-offset: 0px;

  outline: none;
}

.dataTables_empty {
  color: red;
}

.dataTables_wrapper .dataTables_paginate {
  margin-top: 20px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background-color: #44667f;

  color: #fff !important;

  &:hover {
    background-color: #44667f;

    color: #fff !important;
  }
}

.dataTables_wrapper .dataTables_info {
  margin-top: 20px;
}

.heading-btn-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.heading-common {
  label {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    color: #013053;
    letter-spacing: 1px;
  }
}

.buttons-modal {
  button {
    background: linear-gradient(270deg, #e40288 0.63%, #055d9d 97.5%);
    border-radius: 10px 0px;
  }
  a {
    background-color: #055d9d !important;
  }
  button,
  a {
    text-decoration: none;
    border: none;
    color: #fff;
    border-radius: 5px;
    width: 100px;
    display: inline-flex;
    justify-content: center;
    padding: 5px 15px;
  }
}

.admin-main {
  .add-btn {
    text-align: end;

    button {
      border: none;
      color: #fff;
      padding: 7px 10px;
      background: linear-gradient(270deg, #e40288 0.63%, #055d9d 97.5%);
      border-radius: 10px 0px;
      &:hover {
        background-color: #afb57d;
      }

      svg {
        height: 20px;
        margin-bottom: 2px;
      }
    }
  }
  .add-btnFalse {
    text-align: end;

    button {
      border: none;
      color: #fff;
      padding: 7px 10px;
      background: linear-gradient(270deg, #e40288 0.63%, #055d9d 97.5%);
      opacity: 0.5;
      border-radius: 10px 0px;
      &:hover {
        background-color: #afb57d;
      }

      svg {
        height: 20px;
        margin-bottom: 2px;
      }
    }
  }
}

.table-wrapper {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

  .main-data-wrapper {
    max-height: calc(100vh - 324px);
    overflow: auto;
  }
  table {
    width: 100%;
    margin-bottom: 0;
    th {
    }

    td {
      color: #013053 !important;
    }

    min-width: 800px;

    thead {
      background-color: rgba(0, 126, 204, 0.3411764706);

      th {
        color: #055d9d;
        font-weight: 600;
      }
    }

    .action {
      .view {
        background-color: #e40288;
        &:hover {
          background-color: #e4028a83;
          transition: all 0.2s;
        }
      }
      .edit {
        background-color: #e40288;
        &:hover {
          background-color: #e4028a83;
          transition: all 0.2s;
        }
      }
      .delete {
        background-color: #e40288;
        &:hover {
          background-color: #e4028a83;
          transition: all 0.2s;
        }
      }
      .icon {
        img {
          height: 18px;
          width: 18px;
          vertical-align: middle;
        }

        margin: 0 3px;
        padding: 2px 5px;
        border-radius: 5px;
      }
    }
  }
}

table.dataTable thead th {
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 20px;
}

.dataTables_wrapper .dataTables_length select {
  margin-left: 10px;
  width: 150px;
  outline: none;
  border-radius: 10px;
}

.dataTables_wrapper .dataTables_filter input {
  outline-offset: 0px;
  outline: none;
  border-radius: 10px;
}

.dataTables_empty {
  color: red;
}

.dataTables_wrapper .dataTables_paginate {
  margin-top: 20px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background: #055d9d;
  color: #fff !important;

  &:hover {
    background-color: #055d9d;
    color: #fff !important;
  }
}

.dataTables_wrapper .dataTables_info {
  margin-top: 20px;
}

.modal-content {
  .plus-btn,
  .minus-btn {
    button {
      font-size: 15px;
      font-weight: 700;
    }
  }
  .add-newSabaq-btn {
    text-decoration: none;
    width: calc(100% - 1.8rem);
    margin: 0 0.9rem 15px 0.9rem;
    background: linear-gradient(270deg, #e40288 0.63%, #055d9d 97.5%);
    border-radius: 10px 0px;
  }
  .sub-group-main {
    margin-bottom: 15px !important;
    margin: 0 0.9rem;
    padding: 10px;
    width: calc(100% - 1.8rem);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .p-details-img {
    display: flex;
    flex-wrap: wrap;
    img {
      height: 90px;
      width: 90px;
      border-radius: 10px;
      margin-right: 15px;
    }
  }
  .product-img-main {
    flex-wrap: wrap;
    margin: 40px 0 30px 0;
    .products {
      margin-right: 20px;
      margin-bottom: 20px;
      width: 90px;
      height: 90px;
      padding: 10px 0;
      text-align: center;
      position: relative;
      border: 1px dashed gray;
      border-radius: 10px;
      .cross {
        position: absolute;
        right: -8px;
        top: -8px;
        height: 15px;
        width: 15px;
      }
      span {
        font-size: 11px;
        color: gray;
        display: block;
      }
      svg {
        display: inline-block;
        height: 50px;
        width: 50px;
      }
      .img-product {
        height: 70px;
        width: 70px;
      }
    }
  }
  .details-input {
    width: calc(100% - 70px);
    .remove {
      img {
        height: 20px;
        width: 20px;
      }
      cursor: pointer;
      position: absolute;
      right: -55px;
      bottom: 7px;
    }
  }
  .modal-title {
    font-size: 18px;
    font-weight: 600;
    color: #013053;
    text-transform: capitalize;
  }
  .check-input {
    cursor: pointer;
    margin-left: 30px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    .form-check-input {
      height: 30px !important;
      width: 30px;
      margin-right: 10px;
    }
  }
  .user-detail {
    margin-bottom: 15px;
    label {
      color: #84859d;
      font-size: 14px;
    }
    span,
    p {
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      // margin-left: 30px;
    }
  }

  .table-user-details {
    overflow-x: auto;
    table {
      border: 1px solid #44667f;
      border-collapse: collapse;
      min-width: 1000px;
      width: 100%;
      th,
      td {
        padding: 7px 10px;
        border: 1px solid #44667f;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;
      }
      thead {
        .item-heading {
          th {
            color: #84859d;
          }
        }
        .table-heading {
          th {
            color: #44667f;
          }
        }
      }
      tbody {
        td {
          color: #222222;
        }
      }
    }
  }
}
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none;
}

.order-select-input {
  align-items: flex-end;
  button {
    text-decoration: none;
    border: none;
    background-color: #44667f;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
  }
}

.modal-content {
  .key {
    color: #707070;
    font-size: 16px;
  }
  .value {
    font-size: 14px;

    color: #000000;
    font-weight: 600;
  }
}

.wrapper-push-notification {
  .main-section {
    justify-content: space-between;
    h4 {
      font-size: 20px;
      color: #055d9d;
      font-weight: 600;
      margin-bottom: 20px;
    }
    h5 {
      font-size: 16px;
      color: #055d9d;
      font-weight: 600;
    }
  }
  .left-section {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: calc(50% - 5px);
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px;

    p {
      font-size: 14px;
    }

    .step {
      padding: 10px;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      background-color: cream;
    }

    .v-stepper {
      position: relative;
      /*   visibility: visible; */
    }

    /* regular step */
    .step .circle {
      background-color: white;
      border: 10px solid gray;
      border-radius: 100%;
      width: 32px;
      height: 32px;
      display: inline-block;
    }
    .step .line {
      top: 23px;
      left: 14px;
      /*   height: 120px; */
      height: 100%;

      position: absolute;
      border-left: 3px solid #055d9d;
    }

    .step.completed .circle {
      visibility: visible;
      background-color: rgb(6, 150, 215);
      border-color: rgb(6, 150, 215);
    }

    .step.completed .line {
      border-left: 3px solid #055d9d;
    }

    .step.active .circle {
      visibility: visible;
      border-color: #055d9d;
    }

    .step.empty .circle {
      visibility: hidden;
    }

    .step.empty .line {
      /*     visibility: hidden; */
      /*   height: 150%; */
      top: 0;
      height: 150%;
    }

    .step:last-child .line {
      border-left: 3px solid white;
      z-index: -1; /* behind the circle to completely hide */
    }

    .content {
      margin-left: 20px;
      display: inline-block;
    }
  }
  .right-section {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: calc(50% - 5px);
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px;

    .form-control {
      border-radius: 0;
    }
    .form-label {
    }
    button {
      padding: 6px 25px;
      border: none;
      background: linear-gradient(270deg, #e40288 0.63%, #055d9d 97.5%);
      border-radius: 10px 0px;
      color: #ffffff;
    }
  }
}

.active-status {
  color: #15cb15;
}
.inactive-status {
  color: red;
}

.premium-status {
  color: #ffc107;
}
.pagination-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .entry-number {
    label {
      color: #055d9d;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .active > .page-link,
  .page-link.active {
    background-color: #055d9d;
  }
  .page-link {
    // color:#055D9D ;
  }
  .pagination {
    color: #055d9d;
  }
}
.access-heading {
  font-weight: 600;
  font-size: 16px;
}
.documents-wrapper {
  .article-wrapper{
    max-height: calc(100vh - 163px) !important;
  }
  .inner-wrapper {
    max-height: calc(100vh - 263px);
    overflow-y: auto;
    margin-top: 20px;
    .col-md-3 {
      margin-top: 0 !important;
    }
  }
  .article-card{

    height: 360px;
  }
  .document-cards {
    margin-bottom: 15px;
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    h5 {
      margin-bottom: 10px;
      color: #013053;
      font-weight: 600;
      border-bottom: 1px solid gray;
      padding-bottom: 5px;
    }
    .img {
      img {
        width: 100%;
        max-height: 200px;
        border-radius: 10px;
        height: 200px;
      }
    }
  }
}

.document-preview-wrapper {
  img {
    width: 100%;
    border-radius: 10px;
  }
}

.back-btns {
  svg {
    margin-right: 20px;
    // height: 40px;
    margin-top: -5px;
  }
}
.block-btn {
  border: none;
  background-color: #15cb15;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  padding: 2px 10px;
  width: 120px;
}
.unblock-btn {
  width: 120px;
  border: none;
  font-size: 16px;
  background-color: rgba(255, 0, 0, 0.815);
  color: #fff;
  border-radius: 5px;
  padding: 2px 10px;
}

.profile-suggest-card-wrapper {
  margin-top: 10px;
  .name {
    font-weight: 600;
    font-size: 16px;
    color: #013053;
  }
  .age {
    font-size: 16px;
    margin-left: 5px;
    font-weight: 600;
    color: #013053;
  }
  .heights {
    font-size: 14px;
    color: #013053;
  }
}

.t-c-wrapper {
  p {
    color: #013053;
    text-align: justify;
  }
}

.basic-bg {
  color: #fff;
  display: inline-flex;
  width: 132px;
  justify-content: center;
  padding: 3px 10px;
  border-radius: 5px;
  background: linear-gradient(180deg, #03a731 0%, #027221 100%);
}
.Basic-Plus-bg {
  background: linear-gradient(180deg, #fdd504 0%, #b37600 100%);
  color: #fff;
  display: inline-flex;
  width: 132px;
  justify-content: center;
  padding: 3px 10px;
  border-radius: 5px;
}
.premium-bg {
  background: linear-gradient(270deg, #e40288 0.63%, #055d9d 97.5%);
  color: #fff;
  display: inline-flex;
  width: 132px;
  justify-content: center;
  padding: 3px 10px;
  border-radius: 5px;
}
.queries {
  margin-bottom: 15px;
  border-bottom: 1px solid gray;
  h5 {
    font-weight: 600;
    font-size: 16px;
  }
}

.paln-card.p {
  background: linear-gradient(270deg, #e40288 0.63%, #055d9d 97.5%);
}
.paln-card.b {
  background: linear-gradient(180deg, #03a731 0%, #027221 100%);
}
.paln-card.bp {
  background: linear-gradient(180deg, #fdd504 0%, #b37600 100%);
}
.paln-card {
  text-align: center;
  .name-c {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
  }
  padding: 10px;
  border-radius: 10px 0px;
}
.active-plan {
  margin-left: 10px;
  margin-top: 15px;
  .key {
    color: #013053;
    font-size: 16px;
    margin-right: 10px;
    word-wrap: break-word; /* Ensures words break to fit the container */
  overflow-wrap: break-word; /* Helps in breaking long words */
  white-space: pre-wrap; /* Allows text to wrap normally, and respects line breaks */
  }
  .value {
    color: #013053;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }
}
.rdw-editor-main {
  border: 1px solid gray;
  height: 300px;
}

tbody {
  tr {
    cursor: pointer;
  }
}
.user-details-main-card {
  .prf-img {
    position: absolute;
    bottom: -45px;
    left: 20px;
  }
  img {
    height: 35px;
    width: 90px;
    border-radius: 50%;
  }

  .paln-card.p {
    position: relative;
    border-radius: 10px;
    height: 90px;
  }
  .prf-name {
    margin-top: 50px;
  }

  .v_icon {
    width: 30px;
    height: 30px;
  }

  .u-details-inner-card {
    
    padding: 15px 0;
    background: #ffffff;
    border: 1px solid rgba(6, 116, 196, 0.3);
    border-radius: 10px 0px;
    overflow: hidden;
    h3 {
      padding-bottom: 10px;
      font-weight: 700;
      font-size: 18px;
      line-height: 17px;
      color: #013053;
      border-bottom: 1px solid rgba(172, 172, 172, 0.2);
    }
  }
}

.search-wrapper {
  .label-select {
    color: #013053;
    font-weight: 600;
    font-size: 16px;
  }
}
