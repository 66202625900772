/* chart.css */

.chart-container {
    display: flex;
    justify-content: center;
    font-size: 0.75rem; /* equivalent to text-xs */
  }
  
  .tooltip-container {
    padding: 0.5rem;
    background-color: white;
    border: 1px solid #e2e8f0; /* equivalent to border-gray-200 */
  }
  
  .font-medium {
    font-weight: 500; /* This is the equivalent of Tailwind's font-medium */
  }